import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Fragment } from 'react';

import { Stack, Box, Grid, Typography } from '@mui/material';
import Post from '../post/post.component';
import { fetchTipCardContent } from '../../utils/backend/backend.utils';

import './blog-posts.styles.scss';
import { selectTipsData } from '../../store/tips/tips.selector';

const BlogPosts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTipCardContent());
    // eslint-disable-next-line
  }, []);
  const tipsData = useSelector(selectTipsData);

  function tipsRender() {
    if (tipsData) {
      return (
        <Fragment>
          <Grid
            direction="row"
            container
            spacing={2}
            sx={{
              display: { xs: 'none', md: 'none', lg: 'flex' },
              alignSelf: 'stretch'
            }}>
            {tipsData
              .filter((tip, idx) => idx < 4)
              .map((tip) => (
                <Fragment key={tip.tipId}>
                  <Grid item xs={3}>
                    <Post tip={tip} />
                  </Grid>
                </Fragment>
              ))}
          </Grid>
          <Grid
            direction="row"
            container
            spacing={2}
            sx={{
              display: { xs: 'none', md: 'flex', lg: 'none' },
              alignSelf: 'stretch'
            }}>
            {tipsData
              .filter((tip, idx) => idx < 3)
              .map((tip) => (
                <Fragment key={tip.tipId}>
                  <Grid item xs={4}>
                    <Post tip={tip} />
                  </Grid>
                </Fragment>
              ))}
          </Grid>
          <Box sx={{ display: 'flex' }}>
            <Stack
              spacing={2}
              sx={{
                display: { xs: 'inline-block', md: 'none' }
              }}>
              {tipsData
                .filter((tip, idx) => idx < 4)
                .map((tip) => (
                  <Fragment key={tip.tipId}>
                    <Post tip={tip} />
                  </Fragment>
                ))}
            </Stack>
          </Box>
        </Fragment>
      );
    }
    return <Typography>Loading...</Typography>;
  }

  return <Fragment>{tipsRender()}</Fragment>;
};

export default BlogPosts;
