import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  IconButton,
  Modal,
  Fade,
  Box,
  Typography,
  Button,
  TextField,
  Stack,
  Link,
} from '@mui/material';


import {
  setSignInModal,
  setCreateAccountModal,
  emailSignInStart,
} from '../../store/user/user.action';
import { selectSignInModal } from '../../store/user/user.selector';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateAccount from '../create-account-modal/create-account-modal.component';
const defaultSignInFields = {
  email: '',
  password: '',
};

const SignIn = () => {
  const dispatch = useDispatch();
  const signInModal = useSelector(selectSignInModal);

  const [formFields, setFormFields] = useState(defaultSignInFields);
  const { email, password } = formFields;
  const resetFormFields = () => {
    setFormFields(defaultSignInFields);
  };

  const handleSignInOpen = () => dispatch(setSignInModal(true));
  const handleSignInClose = () => dispatch(setSignInModal(false));

  const handleCreateAccountOpen = () => {
    handleSignInClose();
    dispatch(setCreateAccountModal(true));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      dispatch(emailSignInStart(email, password));
      resetFormFields();
      handleSignInClose();
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          alert('incorrect password for email');
          break;
        case 'auth/user-not-found':
          alert('no user associated with this email');
          break;
        default:
          console.log(error);
      }
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          height: '150px',
          display: 'flex',
          p: 2,
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={0}
        >
          <IconButton onClick={handleSignInOpen}>
            <AddCircleIcon fontSize="large" sx={{ color: '#6750A4' }} />
          </IconButton>

          <Typography variant="h6">Sign In</Typography>

          <Typography variant="body2">
            Or{' '}
            <Link
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={handleCreateAccountOpen}
            >
              create an account.
            </Link>
          </Typography>
        </Stack>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={signInModal}
          onClose={handleSignInClose}
          closeAfterTransition
        >
          <Fade in={signInModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
            >
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography id="sign-in-title" variant="h6" component="h2">
                  Sign In
                </Typography>
                <TextField
                  fullWidth
                  id="email-input"
                  name="email"
                  label="email"
                  type="text"
                  variant="outlined"
                  onChange={handleChange}
                  value={email}
                />
                <TextField
                  fullWidth
                  id="password-input"
                  name="password"
                  label="password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  value={password}
                />

                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <Typography variant="subtitle2">
                  Or&nbsp;
                  <Link
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={handleCreateAccountOpen}
                  >
                    Create an account
                  </Link>
                </Typography>
                <Typography variant="subtitle2">
                  <Link>Forgot your password?</Link>
                </Typography>
              </Stack>
            </Box>
          </Fade>
        </Modal>
        <CreateAccount />
      </Box>
    </Fragment>
  );
};

export default SignIn;
