import { takeLatest, all, call, put, select } from "redux-saga/effects";

import { TIMELINE_ACTION_TYPES } from "./timeline.types";
import { getCurrentUser } from "../../utils/firebase/firebase.utils";
import { addAnalysisFailed, addAnalysisSuccess, fetchTimelineFailed, fetchTimelineSuccess, fetchTimelineMapEntryFailed, fetchTimelineMapEntrySuccess } from "./timeline.action";
import { addAnalysisToTimeline, fetchAnalysisData, fetchTimelineData } from "../../utils/backend/backend.utils";
import { selectTimelineAnalysisData } from "./timeline.selector";

export function* getTimelineData() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (!userAuth.uid) return;
    const timelineData = yield call(fetchTimelineData, userAuth.uid);
    yield put(fetchTimelineSuccess(timelineData));
  } catch (error) {
    yield put(fetchTimelineFailed(error));
  }
}

export function* getTimelineAnalysis({ payload: { analysis } }) {
  try {
    const analysisData = yield call(fetchAnalysisData, analysis);
    const timelineAnalysisData = yield select(selectTimelineAnalysisData);
    yield put(fetchTimelineMapEntrySuccess(timelineAnalysisData, analysisData));
  } catch (error) {
    yield put(fetchTimelineMapEntryFailed(error));
  }
}

export function* addAnalysis({ payload: { uid, analysis } }) {
  try {
    yield call(addAnalysisToTimeline, uid, analysis);
    yield put(addAnalysisSuccess());
  } catch (error) {
    yield put(addAnalysisFailed(error));
  }
}

export function* onFetchTimelineStart() {
  yield takeLatest(
    TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_START,
    getTimelineData
  );
}

export function* onFetchTimelineMapEntryStart() {
  yield takeLatest(
    TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_START,
    getTimelineAnalysis
  );
}

export function* onAddAnalysis() {
  yield takeLatest(
    TIMELINE_ACTION_TYPES.ADD_ANALYSIS_START,
    addAnalysis
  );
}

export function* timelineSaga() {
  yield all([call(onFetchTimelineStart), call(onFetchTimelineMapEntryStart), call(onAddAnalysis)]);
}
