import { createAction } from "../../utils/reducer/reducer.utils";
import { TIMELINE_ACTION_TYPES } from "./timeline.types";

const addTimelineAnalysisData = (analysisData, analysisToAdd) => {
  const existingAnalysisData = analysisData.find(
    (analysisData) => analysisData.analysis_id === analysisToAdd.analysis_id
  );
  if (existingAnalysisData) {
    console.log(analysisData);
    return [...analysisData];
  }
  return [...analysisData, { ...analysisToAdd }];
};
export const fetchTimeline = () =>
  createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_START);

export const fetchTimelineSuccess = (timelineData) =>
  createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_SUCCESS, timelineData);

export const fetchTimelineFailed = (error) =>
  createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_FAILED, error);

export const fetchTimelineMapEntryStart = (analysis) =>
  createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_START, { analysis });

export const fetchTimelineMapEntrySuccess = (analysisData, newAnalysis) => {
  const newTimelineAnalysisData = addTimelineAnalysisData(analysisData, newAnalysis);
  return createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_SUCCESS, newTimelineAnalysisData);
}

export const fetchTimelineMapEntryFailed = (error) =>
  createAction(TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_FAILED, error);

export const addAnalysis = (uid, analysis) => createAction(TIMELINE_ACTION_TYPES.ADD_ANALYSIS_START, { uid, analysis });

export const addAnalysisSuccess = () => createAction(TIMELINE_ACTION_TYPES.ADD_ANALYSIS_SUCCESS);

export const addAnalysisFailed = (error) => createAction(TIMELINE_ACTION_TYPES.ADD_ANALYSIS_FAILED, error);