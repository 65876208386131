import { Box, Divider, Typography } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const PageHeader = ({ text }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Logo height="54px" />
      <Typography variant="body1" sx={{ paddingTop: '20px' }}>
        {text}
      </Typography>
      <Divider sx={{ paddingBottom: 2 }} />
    </Box>
  );
};

export default PageHeader;
