import { Fragment } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import { Typography } from "@mui/material";
import TimelineEntry from "../timeline-entry/timeline-entry.component";
import { useSelector } from "react-redux";
import { selectTimelineData } from "../../store/timeline/timeline.selector";

const HairTimeline = () => {
  const timelineData = useSelector(selectTimelineData);

  function timelineRender() {
    if (timelineData) {
      return (
        <Fragment>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem key="new-entry">
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Add a new post</TimelineContent>
            </TimelineItem>
            {timelineData.map((analysis) => (
              <TimelineItem key={analysis.analysis_id}>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <TimelineEntry analysis={analysis} />
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Fragment>
      );
    }
    return <Typography>Loading...</Typography>;
  }

  return <Fragment>{timelineRender()}</Fragment>;
};

export default HairTimeline;
