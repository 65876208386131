import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const QuestionIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M10.7032 15.7261C10.7199 14.4663 10.8654 13.5435 11.1397 12.9576C11.4139 12.3717 11.9154 11.7638 12.644 11.1337C13.3321 10.5283 13.8556 9.95948 14.2147 9.42733C14.5737 8.89519 14.7532 8.32322 14.7532 7.7114C14.7532 6.98532 14.5122 6.38124 14.0301 5.89918C13.5481 5.41711 12.877 5.17608 12.0168 5.17608C11.1741 5.17608 10.5174 5.41493 10.0467 5.89265C9.57608 6.37038 9.23042 6.89385 9.00977 7.46305L6.69455 6.44838C7.08115 5.40526 7.7269 4.5279 8.6318 3.8163C9.53668 3.1047 10.6645 2.7489 12.0154 2.7489C13.7188 2.7489 15.0305 3.22465 15.9505 4.17615C16.8705 5.12765 17.3305 6.27473 17.3305 7.6174C17.3305 8.46522 17.1586 9.22709 16.8149 9.903C16.4713 10.5789 15.9225 11.2692 15.1685 11.9739C14.3638 12.7294 13.8761 13.3164 13.7054 13.7351C13.5348 14.1537 13.4431 14.8174 13.4304 15.7261H10.7032ZM12.0156 22.1674C11.4932 22.1674 11.0483 21.9836 10.6807 21.616C10.3131 21.2485 10.1293 20.8041 10.1293 20.2829C10.1293 19.7617 10.313 19.3174 10.6802 18.9498C11.0475 18.5822 11.4929 18.3984 12.0164 18.3984C12.5399 18.3984 12.9854 18.5822 13.353 18.9498C13.7206 19.3174 13.9043 19.7617 13.9043 20.2829C13.9043 20.8041 13.7201 21.2485 13.3517 21.616C12.9833 21.9836 12.5379 22.1674 12.0156 22.1674Z" />
      </svg>
    </SvgIcon>
  );
};

export default QuestionIcon;
