import {
  Card,
  Divider,
  Grid,
  Typography,
  CardContent,
  CardMedia,
  Stack,
  Box,
  Link,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";
import RouteIcon from "@mui/icons-material/Route";

import { selectAnalysisResult } from "../../store/analysis/analysis.selector";
import { addAnalysis } from "../../store/timeline/timeline.action";
import { selectCurrentUser } from "../../store/user/user.selector";

const AnalyzerResultDesktop = () => {
  const dispatch = useDispatch();
  const [imgSelect, setImgSelect] = useState("");

  const analysisResults = useSelector(selectAnalysisResult);
  const user = useSelector(selectCurrentUser);
  console.log(user.uid);
  const handleChange = (event) => {
    setImgSelect(event.target.value);
  };
  const onAddAnalysis = () => {
    console.log("add analysis");
    dispatch(addAnalysis(user.uid, analysisResults.analysis_id));
  };
  function imageRender(results) {
    switch (imgSelect) {
      case "baseImage":
        return results.image_signed_url;
      default:
        return results.analysis_image_signed_url;
    }
  }
  return (
    <Fragment>
      <Box>
        <Stack sx={{ paddingTop: 2 }}>
          <Card sx={{ bgcolor: "background.periwinkle" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Card sx={{ maxWidth: 240 }}>
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        sx={{ height: 340, width: 240, borderRadius: 1 }}
                        image={imageRender(analysisResults)}
                        title="bald phil"
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 5,
                          left: 10,
                          width: "78px",
                          bgcolor: "rgba(0, 0, 0, 0.54)",
                          color: "white",
                          padding: "5px",
                          height: "20px",
                          borderRadius: "5px",
                        }}
                      >
                        <Typography variant="subtitle2">08/25/2023</Typography>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={4} display="grid">
                  <Stack
                    justifyContent="space-evenly"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <Box>
                      <CircleIcon sx={{ paddingRight: 2, color: "#26D737" }} />
                      <Typography display="inline" variant="h4">
                        96%&nbsp;
                      </Typography>
                      <Typography display="inline" variant="body1">
                        Hair density of entire head
                      </Typography>
                      <Typography variant="body2">
                        <Link>Results don’t look correct?</Link>
                      </Typography>
                      <Stack direction="row">
                        <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                          <InputLabel
                            id="demo-simple-select-label"
                            sx={{ fontSize: "14px", height: 30 }}
                          >
                            Hair Loss Analysis
                          </InputLabel>
                          <Select
                            labelId="imageSelection"
                            id="imageSelection"
                            label="imageSelection"
                            value={imgSelect}
                            onChange={handleChange}
                          >
                            <MenuItem value={"default"}>
                              Hair loss analysis
                            </MenuItem>
                            <MenuItem value={"baseImage"}>
                              Uploaded image
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Typography
                          display="inline"
                          variant="subtitle2"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          &nbsp;Select view
                        </Typography>
                      </Stack>
                    </Box>
                    <Box>
                      <CircleIcon sx={{ paddingRight: 2, color: "#26D737" }} />
                      <Typography display="inline" variant="h4">
                        {analysisResults.scalp_to_crown_ratio.slice(0, 2)}
                        %&nbsp;
                      </Typography>
                      <Typography display="inline" variant="body1">
                        Size of identified spot
                      </Typography>
                      <Typography variant="body2">
                        Hair loss size of scalp-visible area is 1% of crown
                        (cowlicks are included)
                      </Typography>
                    </Box>
                    <Box>
                      <CircleIcon sx={{ paddingRight: 2, color: "#26D737" }} />
                      <Typography display="inline" variant="h4">
                        {analysisResults.hair_in_bald_spot.slice(0, 2)}%&nbsp;
                      </Typography>
                      <Typography display="inline" variant="body1">
                        Hair density at identified spot
                      </Typography>
                      <Typography variant="body2">
                        The density of hair found in the detected area of hair
                        loss is 31%
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={1} display="grid" sx={{ p: 2 }}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ mr: "-1px" }}
                  />
                </Grid>
                <Grid item xs={4} display="grid">
                  <Box>
                    <RouteIcon fontSize="large" />
                    <Typography display="inline" variant="subtitle2">
                      &nbsp;Add result to your hair journey.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      justifyContent: "flex-start",
                      alignContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body1">
                      Say goodbye to uncertainty and hello to a visual timeline
                      of your hair&apos;s evolution. Empower yourself with the
                      data needed to take control of a process generally riddled
                      with ambiguity. Are my treatments working? Have my
                      investments in those treatments paid off? Hold those
                      investments accountable with reliable tracking of your
                      hair journey.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      justifyContent: "flex-start",
                      alignContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={onAddAnalysis}
                      sx={{
                        borderRadius: "100px",
                        textTransform: "none",
                        height: "40px",
                      }}
                    >
                      Add results to Hair Journey
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default AnalyzerResultDesktop;
