import { createAction } from "../../utils/reducer/reducer.utils";
import { ANALYSIS_ACTION_TYPES } from "./analysis.types";

export const setAnalysisFormData = (analysisFormData) =>
    createAction(ANALYSIS_ACTION_TYPES.SET_ANALYSIS_FORM_DATA, analysisFormData);

export const setAnalysisJobStatus = (analysisJobStatus) =>
    createAction(ANALYSIS_ACTION_TYPES.SET_ANALYSIS_JOB_STATUS, analysisJobStatus);

export const setAnalysisJobId = (analysisJobId) =>
    createAction(ANALYSIS_ACTION_TYPES.SET_ANALYSIS_JOB_ID, analysisJobId);

export const setAnalysisResult = (analysisResult) =>
    createAction(ANALYSIS_ACTION_TYPES.SET_ANALYSIS_RESULT, analysisResult);