import { Fragment } from 'react';
import SettingsMenu from '../../components/settings-menu/settings-menu.component';
import PageHeader from '../../components/page-header/page-header.component';
const Settings = () => {
  return (
    <Fragment>
      <PageHeader />
      <SettingsMenu />
    </Fragment>
  );
};
export default Settings;
