import { ANALYSIS_ACTION_TYPES } from "./analysis.types";

const INITIAL_STATE = {
    analysisFormData: null,
    analysisJobStatus: 'empty',
    analysisJobId: null,
    analysisResult: null,
}

export const analysisReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case ANALYSIS_ACTION_TYPES.SET_ANALYSIS_FORM_DATA:
            return {
                ...state,
                analysisFormData: payload
            }
        case ANALYSIS_ACTION_TYPES.SET_ANALYSIS_JOB_STATUS:
            return {
                ...state,
                analysisJobStatus: payload
            }
        case ANALYSIS_ACTION_TYPES.SET_ANALYSIS_JOB_ID:
            return {
                ...state,
                analysisJobId: payload
            }
        case ANALYSIS_ACTION_TYPES.SET_ANALYSIS_RESULT:
            return {
                ...state,
                analysisResult: payload
            }
        default:
            return state;
    }
}

