import { takeLatest, all, call, put } from 'redux-saga/effects';

import { fetchUserData } from '../../utils/backend/backend.utils';

import { signInFailed, signInSuccess } from './user.action';

import { USER_ACTION_TYPES } from './user.types';

import {
  getCurrentUser,
  signInAuthUserWithEmailAndPassword
} from '../../utils/firebase/firebase.utils';
import { fetchTimeline } from '../timeline/timeline.action';

export function* getUserDataFromUserAuth(userAuth) {
    try {
        const userData = yield call(fetchUserData, userAuth.uid);
        yield put(signInSuccess({currentUser: userAuth, userData }));
        yield put(fetchTimeline())
    } catch (error) {
        yield put(signInFailed(error));
    }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const user = yield call(signInAuthUserWithEmailAndPassword, email, password);
    yield call(getUserDataFromUserAuth, user);
    
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (!userAuth) return;
    yield call(getUserDataFromUserAuth, userAuth);
  } catch (error) {
    yield put(signInFailed(error));
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(USER_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(USER_ACTION_TYPES.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* userSaga() {
  yield all([call(onCheckUserSession), call(onEmailSignInStart)]);
}
