import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BookIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M8.025 20.2152C7.56502 20.2152 7.16614 20.0458 6.82838 19.7068C6.49061 19.3679 6.32173 18.9676 6.32173 18.506V15.9581H9.47555V12.7831C8.85417 12.8212 8.2298 12.7267 7.60245 12.4997C6.97508 12.2727 6.41594 11.9208 5.92503 11.4439V10.0141H4.69728L1.3864 6.69727C2.00633 6.1306 2.73496 5.66412 3.57228 5.29782C4.40959 4.93152 5.26358 4.74837 6.13425 4.74837C6.61432 4.74837 7.16821 4.82654 7.79593 4.98287C8.42364 5.13922 8.98352 5.38641 9.47555 5.72444V3.77879H21.1555V17.4348C21.1555 18.2066 20.885 18.863 20.3441 19.4039C19.8032 19.9448 19.1469 20.2152 18.375 20.2152H8.025ZM11.1788 15.9581H17.3038V17.4348C17.3038 17.7562 17.4026 18.015 17.6002 18.2114C17.7978 18.4078 18.0557 18.506 18.3738 18.506C18.692 18.506 18.9503 18.4078 19.1487 18.2114C19.347 18.015 19.4462 17.7562 19.4462 17.4348V5.48804H11.1788V6.92499L17.0484 12.7945V13.9234H15.9255L12.8593 10.8511L12.4581 11.3272C12.2529 11.5639 12.0505 11.745 11.8507 11.8704C11.651 11.9959 11.427 12.1155 11.1788 12.2293V15.9581ZM5.3962 8.44837H7.48913V10.6853C7.76449 10.8647 8.03768 10.9992 8.3087 11.0888C8.57972 11.1785 8.8549 11.2234 9.13425 11.2234C9.54293 11.2234 9.96096 11.1109 10.3883 10.8859C10.8157 10.6609 11.1241 10.4383 11.3137 10.2181L11.7245 9.73424L10.0294 8.03314C9.4952 7.49981 8.89828 7.07998 8.23858 6.77364C7.57886 6.46729 6.87742 6.31412 6.13425 6.31412C5.69222 6.31412 5.29384 6.36729 4.93913 6.47364C4.58443 6.57998 4.22138 6.72282 3.85 6.90217L5.3962 8.44837ZM15.6005 17.5239H8.025V18.506H15.9506C15.8465 18.4139 15.7622 18.2814 15.6975 18.1084C15.6329 17.9354 15.6005 17.7406 15.6005 17.5239Z" />
      </svg>
    </SvgIcon>
  );
};

export default BookIcon;
