import { TIPS_ACTION_TYPES } from "./tips.types";

const INITIAL_STATE = {
    tipsData: null
}

export const tipsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TIPS_ACTION_TYPES.SET_TIPS_DATA:
            return {
                ...state,
                tipsData: payload
            }
        default:
            return state;
    }
}

