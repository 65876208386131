import { combineReducers } from 'redux';

import { userReducer } from './user/user.reducer';
import { timelineReducer } from './timeline/timeline.reducer';
import { tipsReducer } from './tips/tips.reducer';
import { analysisReducer } from './analysis/analysis.reducer';

export const rootReducer = combineReducers({
    user: userReducer,
    tips: tipsReducer,
    timeline: timelineReducer,
    analysis: analysisReducer
});
