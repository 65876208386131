import { Fragment } from 'react';
import ErrorImage from '../../assets/404ErrorRip.png';

import { Stack, Box, Typography, Button } from '@mui/material';
const UnderConstruction = () => {
  return (
    <Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'stretch',
          flexDirection: 'column',
          alignContent: 'stretch',
        }}
      >
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            maxWidth={300}
            spacing={2}
          >
            <Typography align="right" variant="h4">
              We're still combing out the details
            </Typography>
            <Typography align="right" variant="body2">
              This page is under construction and will become available soon.
            </Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: '100px',
                textTransform: 'none',
                width: 150,
              }}
            >
              Go back
            </Button>
          </Stack>
          <img src={ErrorImage} width={350} height={350} alt="loading..." />
        </Stack>
      </Box>
    </Fragment>
  );
};

export default UnderConstruction;
