import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';

const Post = ({ tip }) => {
  const { tipId, imageUrl, tipContent, tipTitle } = tip;
  return (
    <Fragment key={tipId}>
      <Card
        sx={{
          height: 400,
          display: 'grid',
          gridTemplateRows: 'repeat(4, 1fr)',
          gridGap: '2px',
        }}
      >
        <CardMedia
          sx={{ height: 140 }}
          image={`${imageUrl}`}
          title="tip image"
        />
        <CardContent sx={{ gridRow: 'span 2' }}>
          <Typography gutterBottom variant="h5" component="div">
            {tipTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {tipContent}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Button sx={{ textTransform: 'none' }}>Open</Button>
        </CardActions>
      </Card>
    </Fragment>
  );
};

export default Post;
