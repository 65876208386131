import { useSelector, useDispatch } from "react-redux";
import AnalyzerForm from "../analyzer-form/analyzer-form.component";
import AnalyzerResult from "../analyzer-result/analyzer-result.component";
import { Fragment, useEffect } from "react";
import Loading from "../loading/loading.component";
import { selectAnalysisJobStatus } from "../../store/analysis/analysis.selector";
import {
  setSignInModal,
  setCreateAccountModal,
} from "../../store/user/user.action";
import {
  setAnalysisJobStatus,
  setAnalysisJobId,
  setAnalysisResult,
} from "../../store/analysis/analysis.action";

import { checkJobStatus } from "../../utils/backend/backend.utils";
import PageHeader from "../page-header/page-header.component";
import { Link, Typography } from "@mui/material";
import { selectCurrentUser } from "../../store/user/user.selector";

const Analyzer = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const analyzerState = useSelector(selectAnalysisJobStatus);
  const loadingText = "Checking for hair loss...";

  const handleSignInOpen = () => dispatch(setSignInModal(true));
  const handleCreateAccountOpen = () => dispatch(setCreateAccountModal(true));

  const checkJobState = async () => {
    try {
      dispatch(checkJobStatus());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (analyzerState === "processing") {
      console.log("Analyzer state " + analyzerState);
      const intervalCall = setInterval(() => {
        checkJobState();
      }, 5000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
  });

  const resetAnalyzer = () => {
    dispatch(setAnalysisJobStatus(null));
    dispatch(setAnalysisJobId(null));
    dispatch(setAnalysisResult(null));
  };

  function analyzerRender() {
    const textResults = (
      <Fragment>
        <Typography
          component={"span"}
          variant="body1"
          sx={{ paddingTop: "20px" }}
        >
          Review the results of image analysis below.&nbsp;
          <Link
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={resetAnalyzer}
          >
            Analyze a new image here.
          </Link>
        </Typography>
      </Fragment>
    );

    const loginText = (
      <Fragment>
        <Typography
          component={"span"}
          variant="body1"
          sx={{ paddingTop: "20px" }}
        >
          <Link
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleSignInOpen}
          >
            Sign in
          </Link>
          &nbsp;or&nbsp;
          <Link
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={handleCreateAccountOpen}
          >
            Create an account
          </Link>
          &nbsp;to utilize the Hair Journey feature and track hair loss and
          treatment plans over time.
        </Typography>
      </Fragment>
    );
    const textForm = (
      <Fragment>
        <Typography
          component={"span"}
          variant="body1"
          sx={{ paddingTop: "20px" }}
        >
          Experiencing hair loss? Use our image analyzer to estimate a thinning
          crown region&apos;s size and density.
          <br />
        </Typography>
        {!currentUser ? loginText : null}
      </Fragment>
    );
    switch (analyzerState) {
      case "processing":
        return (
          <Fragment>
            <PageHeader text={textForm} />
            <Loading text={loadingText} />
          </Fragment>
        );
      case "success":
        return (
          <Fragment>
            <PageHeader text={textResults} />
            <AnalyzerResult />
          </Fragment>
        );
      case "failure":
        return (
          <Fragment>
            <PageHeader text={textForm} />
            <AnalyzerForm />
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <PageHeader text={textForm} />
            <AnalyzerForm />
          </Fragment>
        );
    }
  }

  return analyzerRender();
};

export default Analyzer;
