import { Fragment, useEffect } from "react";

import { IconButton, Stack, Typography, Box, Link } from "@mui/material";

import LogoutIcon from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { selectUserData } from "../../store/user/user.selector";

const SignOut = () => {
  const signOutHandler = async () => {
    console.log("logout");
  };
  const userData = useSelector(selectUserData);
  const { first_name, last_name } = userData;

  return (
    <Fragment>
      <Box
        sx={{
          height: "150px",
          display: "flex",
          p: 2
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={0}
        >
          <IconButton onClick={signOutHandler}>
            <LogoutIcon />
          </IconButton>
          <Typography variant="h6">
            {first_name} {last_name}
          </Typography>
          <Link
            variant="body2"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
            onClick={signOutHandler}
          >
            Sign out
          </Link>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default SignOut;
