import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const UploadIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M6.26195 20.1495C4.7874 20.1495 3.51886 19.6195 2.45633 18.5596C1.39378 17.4996 0.862503 16.2337 0.862503 14.762C0.862503 13.438 1.27699 12.2653 2.10598 11.2438C2.93496 10.2222 4.00942 9.60742 5.32935 9.39945C5.67862 7.77084 6.47824 6.44204 7.7282 5.41305C8.97815 4.38405 10.4193 3.86955 12.0518 3.86955C13.9499 3.86955 15.5464 4.55072 16.8413 5.91305C18.1362 7.27537 18.7996 8.9125 18.8315 10.8245V11.4245C20.0435 11.427 21.0641 11.8428 21.8935 12.6719C22.7228 13.5009 23.1375 14.5393 23.1375 15.787C23.1375 16.9805 22.7059 18.0059 21.8427 18.8634C20.9794 19.7208 19.9529 20.1495 18.7631 20.1495H12.9234C12.4637 20.1495 12.0639 19.9796 11.724 19.6398C11.3841 19.3 11.2141 18.9021 11.2141 18.4462V12.2712L9.10925 14.3821L7.9864 13.2652L12 9.24565L16.0196 13.2652L14.8908 14.3821L12.7859 12.2712V18.4462H18.7652C19.4938 18.4462 20.1208 18.1838 20.6462 17.6589C21.1716 17.1341 21.4343 16.5061 21.4343 15.775C21.4343 15.041 21.1718 14.4122 20.647 13.8889C20.1221 13.3655 19.4942 13.1038 18.7631 13.1038H17.1343V10.9381C17.1343 9.4729 16.6368 8.21341 15.6418 7.15958C14.6468 6.10573 13.4172 5.5788 11.9528 5.5788C10.4796 5.5788 9.24257 6.11314 8.24185 7.1818C7.24112 8.25045 6.74075 9.52445 6.74075 11.0038H6.21195C5.19349 11.0038 4.33117 11.3609 3.625 12.0752C2.91884 12.7895 2.56575 13.6724 2.56575 14.7239C2.56575 15.742 2.92687 16.6169 3.6491 17.3487C4.37132 18.0804 5.24327 18.4462 6.26495 18.4462H9.71413V20.1495H6.26195Z" />
      </svg>
    </SvgIcon>
  );
};

export default UploadIcon;
