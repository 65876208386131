import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const RouteIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M19.15 21.1375C18.4228 21.1375 17.7951 20.9214 17.2669 20.4891C16.7386 20.0569 16.4058 19.5391 16.2685 18.9359H11.075C9.96185 18.9359 9.04009 18.5704 8.3097 17.8394C7.57932 17.1085 7.21413 16.186 7.21413 15.0721C7.21413 13.9621 7.57932 13.0416 8.3097 12.3106C9.04009 11.5796 9.96185 11.2141 11.075 11.2141H13C13.6702 11.2141 14.2193 11 14.6472 10.5718C15.0752 10.1436 15.2891 9.59426 15.2891 8.92373C15.2891 8.25319 15.0752 7.70425 14.6472 7.2769C14.2193 6.84955 13.6702 6.63588 13 6.63588H7.7375C7.57954 7.23913 7.24059 7.75688 6.72065 8.18913C6.20072 8.62138 5.57717 8.8375 4.85 8.8375C4.01447 8.8375 3.30767 8.54844 2.7296 7.9703C2.15154 7.39217 1.8625 6.68529 1.8625 5.84965C1.8625 5.01402 2.15154 4.30725 2.7296 3.72935C3.30767 3.15145 4.01447 2.8625 4.85 2.8625C5.57717 2.8625 6.20072 3.07863 6.72065 3.51088C7.24059 3.94313 7.57954 4.46088 7.7375 5.06413H13C14.112 5.06413 15.0334 5.42962 15.7644 6.1606C16.4954 6.89159 16.8609 7.81305 16.8609 8.925C16.8609 10.037 16.4954 10.9584 15.7644 11.6894C15.0334 12.4204 14.112 12.7859 13 12.7859H11.075C10.4036 12.7859 9.85425 12.9996 9.4269 13.4269C8.99955 13.8543 8.78588 14.4027 8.78588 15.0721C8.78588 15.7454 8.99955 16.2958 9.4269 16.7231C9.85425 17.1505 10.4036 17.3641 11.075 17.3641H16.2685C16.4225 16.7609 16.7594 16.2431 17.2794 15.8109C17.7993 15.3786 18.4228 15.1625 19.15 15.1625C19.9855 15.1625 20.6923 15.4516 21.2704 16.0297C21.8485 16.6078 22.1375 17.3147 22.1375 18.1504C22.1375 18.986 21.8485 19.6928 21.2704 20.2707C20.6923 20.8486 19.9855 21.1375 19.15 21.1375ZM4.85 7.2C5.23334 7.2 5.55417 7.07084 5.8125 6.8125C6.07084 6.55417 6.2 6.23334 6.2 5.85C6.2 5.46667 6.07084 5.14584 5.8125 4.8875C5.55417 4.62917 5.23334 4.5 4.85 4.5C4.46667 4.5 4.14584 4.62917 3.8875 4.8875C3.62917 5.14584 3.5 5.46667 3.5 5.85C3.5 6.23334 3.62917 6.55417 3.8875 6.8125C4.14584 7.07084 4.46667 7.2 4.85 7.2Z" />
      </svg>
    </SvgIcon>
  );
};

export default RouteIcon;
