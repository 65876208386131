import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import * as FormData from "form-data";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Button,
  Card,
  CardContent,
  FormControl,
  Typography,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import "./analyzer.styles.scss";
import UploadIcon from "../icons/upload.icon";
import { startNewAnalysis } from "../../utils/backend/backend.utils";
import { setAnalysisFormData } from "../../store/analysis/analysis.action";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AnalyzerForm = () => {
  const dispatch = useDispatch();

  const onSubmitAnalysis = () => {
    dispatch(startNewAnalysis());
  };
  const [uploadText, setUploadText] = useState("");
  const [imageDate, setImageDate] = useState(dayjs());
  const onFileChange = (event) => {
    if (event.target.files[0]) {
      const imageFile = event.target.files[0];
      //console.log(event.target.files[0].name);
      setUploadText(event.target.files[0].name);
      const analysisFormData = new FormData();
      analysisFormData.append("file", imageFile);
      analysisFormData.append(
        "data",
        JSON.stringify({ date_taken: imageDate }),
      );
      dispatch(setAnalysisFormData(analysisFormData));
    }
  };
  return (
    // Need to wrap this code with a grid structure for each sub component.
    <Fragment>
      <Stack spacing={2} sx={{ paddingTop: 2 }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<UploadIcon fill="#fff" stroke="white" />}
          fullWidth={true}
          sx={{
            borderRadius: "4px 4px 0px 0px",
            display: "flex",
            alignSelf: "stretch",
            height: "50px",
            justifyContent: "flex-start",
            textTransform: "none",
          }}
        >
          {uploadText
            ? uploadText
            : "Upload image: Browse or drag and drop here"}
          <VisuallyHiddenInput type="file" onChange={onFileChange} />
        </Button>
        <Typography variant="body2">
          Limit 200MB per file - JPG, PNG, JPEG
        </Typography>
        <Card sx={{ bgcolor: "background.periwinkle" }}>
          <CardContent>
            <Typography variant="subtitle2" sx={{ margin: "0px" }}>
              Instructions:
            </Typography>
            <Typography variant="body1" sx={{ margin: "0px" }}>
              1. Upload an image. Include your entire head, taken from above as
              shown in the icon to the left. View detailed instructions.
              <br />
              2. Run the analysis. Check Terms of Service and the date, then
              select the &apos;Checkup on hair loss&apos; button, and wait a few
              moments.
              <br />
              3. Review your results. If you have an account, you can add the
              results to your Hair Journey.
              <br />
            </Typography>
          </CardContent>
        </Card>
        <FormControl
          required
          sx={{
            display: "block",
            md: { width: "45%" },
            xs: { width: "450px" },
          }}
        >
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={imageDate}
                label="Basic date picker"
                onChange={setImageDate}
                sx={{ width: "150px" }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              sx={{ borderRadius: "100px", textTransform: "none" }}
              onClick={onSubmitAnalysis}
            >
              Checkup on hair loss
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Fragment>
  );
};

export default AnalyzerForm;
