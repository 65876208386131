export const TIMELINE_ACTION_TYPES = {
    FETCH_TIMELINE_DATA_START: 'FETCH_TIMELINE_DATA_START',
    FETCH_TIMELINE_DATA_SUCCESS: 'FETCH_TIMELINE_DATA_SUCCESS',
    FETCH_TIMELINE_DATA_FAILED: 'FETCH_TIMELINE_DATA_FAILED',
    FETCH_TIMELINE_MAP_ENTRY_START: 'FETCH_TIMELINE_MAP_ENTRY_START',
    FETCH_TIMELINE_MAP_ENTRY_SUCCESS: 'FETCH_TIMELINE_MAP_ENTRY_SUCCESS',
    FETCH_TIMELINE_MAP_ENTRY_FAILED: 'FETCH_TIMELINE_MAP_ENTRY_FAILED',
    ADD_ANALYSIS_START: 'ADD_ANALYSIS_START',
    ADD_ANALYSIS_SUCCESS: 'ADD_ANALYSIS_SUCCESS',
    ADD_ANALYSIS_FAILED: 'ADD_ANALYSIS_FAILED'
};
