import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import {
  ListItemIcon,
  List,
  Drawer,
  Box,
  ListItemText,
  ListItemButton,
  Link,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  CssBaseline
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import HomeIcon from "../../components/icons/home.icon";
import QuestionIcon from "../../components/icons/question.icon";
import RouteIcon from "../../components/icons/route.icon";
import MedicationIcon from "../../components/icons/medication.icon";
import BookIcon from "../../components/icons/book.icon";
import SettingsIcon from "../../components/icons/settings.icon";

import SignIn from "../../components/sign-in/sign-in.component";
import SignOut from "../../components/sign-out/sign-out.component";
import { selectCurrentUser } from "../../store/user/user.selector";
import { checkUserSession } from "../../store/user/user.action";

const drawerWidth = 225;

const navList = [
  {
    id: 1,
    title: "Home",
    to: "/",
    icon: <HomeIcon className="icon" />
  },
  {
    id: 2,
    title: "Hair Journey",
    to: "/journey",
    icon: <RouteIcon className="icon" />
  },
  {
    id: 3,
    title: "Treatments",
    to: "/treatments",
    icon: <MedicationIcon className="icon" />
  },
  {
    id: 4,
    title: "Blog",
    to: "/blog",
    icon: <BookIcon className="icon" />
  },
  {
    id: 5,
    title: "About",
    to: "/about",
    icon: <QuestionIcon className="icon" />
  }
];
const authNavList = [
  {
    id: 1,
    title: "Settings",
    to: "/settings",
    icon: <SettingsIcon className="icon" />
  }
];

const Navigation = () => {

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const currentUser = useSelector(selectCurrentUser);
  const { pathname } = useLocation();

  const navDrawer = (
    <div>
      {currentUser ? <SignOut /> : <SignIn />}
      <Divider />
      <List dense>
        {navList.map((item) => {
          const { title, icon, to } = item;
          return (
            <ListItemButton
              component={Link}
              to={to}
              key={title}
              selected={to === pathname}
              sx={{
                "&.Mui-selected": {
                  color: "#ffffff",
                  "& .navIcon": {
                    color: "#ffffff"
                  },
                  "& .navText": {
                    color: "#ffffff"
                  }
                }
              }}
            >
              <ListItemIcon selected={to === pathname} className="navIcon">
                {icon}
              </ListItemIcon>
              <ListItemText
                className="navText"
                primary={title}
                color="dark"
                fontSize="small"
              ></ListItemText>
            </ListItemButton>
          );
        })}
      </List>
      {currentUser ? (
        <List dense>
          {authNavList.map((item) => {
            const { title, icon, to } = item;
            return (
              <ListItemButton
                component={Link}
                to={to}
                key={title}
                selected={to === pathname}
                sx={{
                  "&.Mui-selected": {
                    color: "#ffffff",
                    "& .navIcon": {
                      color: "#ffffff"
                    },
                    "& .navText": {
                      color: "#ffffff"
                    }
                  }
                }}
              >
                <ListItemIcon selected={to === pathname} className="navIcon">
                  {icon}
                </ListItemIcon>
                <ListItemText
                  className="navText"
                  primary={title}
                  color="dark"
                  fontSize="small"
                ></ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      ) : null}
    </div>
  );

  return (
    <Fragment>
      <Box className="window" sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100%-${drawerWidth}px)` },
            display: { md: "none" }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{
            width: { md: drawerWidth },
            flexShrink: { md: 0 }
          }}
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {navDrawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
            open
          >
            {navDrawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { md: `calc(100% - ${drawerWidth}px)` }
          }}
        >
          <Toolbar
            sx={{
              display: { md: "none" }
            }}
          />
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  );
};

export default Navigation;
