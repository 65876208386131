import { TIMELINE_ACTION_TYPES } from "./timeline.types";

const INITIAL_STATE = {
  timelineData: null,
  timelineAnalysisData: [],
  //timelineMap: null,
  error: null,
  isLoading: false
};

export const timelineReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        timelineData: payload
      };
    case TIMELINE_ACTION_TYPES.FETCH_TIMELINE_DATA_FAILED:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    case TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_SUCCESS:
      return {
        ...state,
        timelineAnalysisData: payload
      };
    case TIMELINE_ACTION_TYPES.FETCH_TIMELINE_MAP_ENTRY_FAILED:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    case TIMELINE_ACTION_TYPES.ADD_ANALYSIS_FAILED:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    default:
      return state;
  }
};
