import { Divider, InputAdornment, Stack, TextField } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../store/user/user.selector';
import { Fragment } from 'react';

const SettingsMenu = () => {
  const userData = useSelector(selectUserData);
  const { first_name, last_name, email } = userData;
  return (
    <Fragment>
      <Stack
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        spacing={2}
      >
        <TextField
          fullWidth
          id="firstname-input"
          name="firstname"
          label={first_name}
          type="text"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          id="lastname-input"
          name="lastname"
          label={last_name}
          type="text"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          id="email-input"
          name="email"
          label={email}
          type="text"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          id="password-input"
          name="password"
          label="password"
          type="password"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};

export default SettingsMenu;
