import { Fragment } from 'react';
import Spinner from '../../assets/loading.gif';

import { Stack, Box, Typography } from '@mui/material';
const Loading = ({ text }) => {
  return (
    <Fragment>
      <Box
        sx={{
          p: 4,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={0}
          sx={{ p: 2 }}
        >
          <img src={Spinner} width={125} height={125} alt="loading..." />
          <Typography variant="body1">{text}</Typography>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default Loading;
