import { Typography, Chip, Link } from "@mui/material";
import { Fragment, useState } from "react";
import dayjs from "dayjs";
import AnalysisCard from "../analysis-card/analysis-card.component";

const TimelineEntry = ({ analysis }) => {
  const { hair_in_bald_spot, analysis_id, analysis_date } = analysis;

  const [isOpen, setIsOpen] = useState(false);
  const onShowResult = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  return (
    <Fragment key={analysis_id}>
      <Typography display="inline" variant="body1">
        {dayjs(analysis_date).format("MM/DD/YYYY")}&nbsp;
      </Typography>
      <Chip label={hair_in_bald_spot + " %"} color="error" />
      <Typography display="inline" variant="body1">
        &nbsp;Total Hair Density.{" "}
        <Link onClick={onShowResult}>Show results.</Link>
        {isOpen && <AnalysisCard analysis={analysis_id} />}
      </Typography>
    </Fragment>
  );
};

export default TimelineEntry;
