import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Navigation from './routes/navigation/navigation.component';
import Home from './routes/home/home.component';
import UnderConstruction from './routes/under-construction/under-construction.component';
import Settings from './routes/settings/settings.component';
import { checkUserSession } from './store/user/user.action';

import { checkBackendHealth } from './utils/backend/backend.utils';
import HairJourney from './routes/hair-journey/hair-journey.component';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6750A4',
      light: '#CAC4D0',
      dark: '#1D1B20',
      contrastText: '#FFFFFF'
    },
    background: {
      default: '#f9fafb',
      periwinkle: '#f7f2fa'
    },
    action: {
      hover: '#CAC4D0',
      selected: '#6750A4',
      disabled: '#ffffff',
      disabledOpacity: 0.5,
      selectedOpacity: 0.5,
      activatedOpacity: 0.5
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f9fafb'
        }
      }
    }
  },
  typography: {
    h6: {
      fontSize: 20
    },
    body1: {
      fontSize: 16
    },
    body2: {
      fontSize: 14
    }
  }
});

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkBackendHealth());
    dispatch(checkUserSession());
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="/journey" element={<HairJourney />} />
          <Route path="/treatments" element={<UnderConstruction />} />
          <Route path="/blog" element={<UnderConstruction />} />
          <Route path="/about" element={<UnderConstruction />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;
