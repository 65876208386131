import { Grid, Typography } from "@mui/material";
import PageHeader from "../../components/page-header/page-header.component";
import { Fragment } from "react";
import HairTimeline from "../../components/hair-timeline/hair-timeline.component";

const HairJourney = () => {
  const textForm = (
    <Fragment>
      <Typography
        component={"span"}
        variant="body1"
        sx={{ paddingTop: "20px" }}
      >
        Use the Hair Journey to track your hair loss and treatment progress over
        time.
      </Typography>
    </Fragment>
  );
  return (
    <Grid
      direction="column"
      container
      spacing={2}
      sx={{ display: "flex", alignSelf: "stretch" }}
    >
      <Grid item xs>
        <PageHeader text={textForm} />
      </Grid>
      <Grid item xs>
        <HairTimeline />
      </Grid>
    </Grid>
  );
};
export default HairJourney;
