import { Divider, Grid } from '@mui/material';
import Analyzer from '../../components/analyzer/analyzer.component';
import BlogPosts from '../../components/blog-posts/blog-posts.component';

const Home = () => {
  return (
    <Grid direction="column" container spacing={2} sx={{ display: 'flex', alignSelf: 'stretch' }}>
      <Grid item xs>
        <Analyzer />
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs>
        <BlogPosts />
      </Grid>
    </Grid>
  );
};
export default Home;
