import axios from "axios";

import { setTipsData } from "../../store/tips/tips.action";
import { store } from "../../store/store";
import {
  setAnalysisJobStatus,
  setAnalysisJobId,
  setAnalysisResult
} from "../../store/analysis/analysis.action";

axios.defaults.headers.common["X-API-KEY"] = process.env.REACT_APP_API_KEY;
const BASE_URL = process.env.REACT_APP_API_URL;

export const checkBackendHealth = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BASE_URL}/health`);
      console.log("Checked health: " + response.data.status);
    } catch (error) {
      console.error(error);
    }
  };
};

/**
 * Fetches tip card content from the server.
 * @async
 * @function fetchTipCardContent
 * @returns {Promise<void>} - A promise that resolves when the tip card content is fetched and logged.
 * @throws {Error} - If an error occurs during the fetch request.
 */
export const fetchTipCardContent = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${BASE_URL}/tip_card`);
      const tipsData = response.data.Items;
      dispatch(setTipsData(tipsData));
    } catch (error) {
      console.error(error);
    }
  };
};

//store.dispatch(fetchTipCardContent);

export const startNewAnalysis = () => {
  const state = store.getState();
  const analysisFormData = state.analysis.analysisFormData;
  console.log("Starting new analysis. Form data: " + analysisFormData);
  //const formData = new FormData();
  //formData.append("file", imageFile);
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/analysis/start_analysis`,
        analysisFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      const job_id = response.data;
      dispatch(setAnalysisJobStatus("processing"));
      dispatch(setAnalysisJobId(job_id));
    } catch (error) {
      console.error(error);
    }
  };
};

//store.dispatch(startNewAnalysis);

export const checkJobStatus = () => {
  const state = store.getState();
  //const formData = new FormData();
  //formData.append("file", imageFile);
  return async (dispatch) => {
    try {
      const analysisJobId = state.analysis.analysisJobId.job_id;
      console.log("job analysis id " + analysisJobId);
      const response = await axios.get(
        `${BASE_URL}/analysis/check_status/${analysisJobId}`
      );
      const status = response.data;
      if (status.status === "Completed") {
        await dispatch(fetchCompletedAnalysis());
        dispatch(setAnalysisJobStatus("success"));
      }
      console.log(status);
    } catch (error) {
      console.error(error);
    }
  };
};

//store.dispatch(checkJobStatus);

export const fetchCompletedAnalysis = () => {
  //const formData = new FormData();
  //formData.append("file", imageFile);
  return async (dispatch) => {
    try {
      const state = store.getState();
      const analysisJobId = state.analysis.analysisJobId.job_id;
      console.log("job analysis id " + analysisJobId);
      const response = await axios.get(
        `${BASE_URL}/analysis/get_analysis_from_job/${analysisJobId}`
      );
      const analysis = response.data;
      dispatch(setAnalysisResult(analysis));
      console.log(analysis);
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchAnalysisData = async (analysis_id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/analysis/get/${analysis_id}`
    );
    const analysis = response.data;
    return analysis;
  } catch (error) {
    console.error(error);
  }
};

export const createNewUser = () => {
  return async (dispatch) => {
    try {
      const state = store.getState();
      const userData = state.user.userData;
      const response = await axios.post(`${BASE_URL}/user/create`, userData);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
};

export const fetchUserData = async (uid) => {
  if (!uid) return;
  try {
    const response = await axios.get(`${BASE_URL}/user/id/${uid}`);
    const userData = response.data;
    return userData;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTimelineData = async (uid) => {
  if (!uid) return;
  try {
    // Call api using user id to fetch timeline with all analysis ids, and date/total density for each analysis
    const response = await axios.get(`${BASE_URL}/timeline/analysis/${uid}`);
    const timelineData = response.data;
    return timelineData;
  } catch (error) {
    console.error(error);
  }
};

export const addAnalysisToTimeline = async (uid, analysis) => {
  if (!uid || !analysis) return;
  try {
    // Call api using user id and analysis ID to add analysis id to user's timeline.
    const analysisData = {
      'user_id': `${uid}`,
      'timeline_id': `${uid}`,
      'analysis_id': `${analysis}`
    }
    console.log(analysisData);
    const response = await axios.post(`${BASE_URL}/timeline/add/analysis`, analysisData);
    console.log(response);
    return;
  } catch (error) {
    console.error(error);
  }
};