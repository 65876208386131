import { Box } from '@mui/material';
import { Fragment } from 'react';

import AnalyzerResultMobile from './analyzer-result-mobile.component';
import AnalyzerResultDesktop from './analyzer-result-desktop.component';

const AnalyzerResult = () => {
  return (
    <Fragment>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <AnalyzerResultMobile />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <AnalyzerResultDesktop />
      </Box>
    </Fragment>
  );
};

export default AnalyzerResult;
