import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const MedicationIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M17.0672 7.70816C16.7209 7.70816 16.4198 7.58016 16.1638 7.32418C15.9079 7.06821 15.7799 6.76446 15.7799 6.41291C15.7799 6.17666 15.8964 5.85502 16.1293 5.44801C16.3623 5.04101 16.6744 4.63152 17.0657 4.21956C17.4616 4.63121 17.7768 5.03974 18.0115 5.44516C18.2462 5.85057 18.3636 6.17515 18.3636 6.41888C18.3636 6.76645 18.2376 7.06821 17.9855 7.32418C17.7334 7.58016 17.4273 7.70816 17.0672 7.70816ZM19.9577 14.8277C19.3221 14.8277 18.7765 14.6005 18.3209 14.146C17.8653 13.6916 17.6375 13.1464 17.6375 12.5106C17.6375 11.9807 17.8736 11.3158 18.3459 10.516C18.8182 9.71622 19.356 8.96467 19.9592 8.26141C20.5418 8.96467 21.0735 9.71391 21.5541 10.5091C22.0347 11.3043 22.275 11.9721 22.275 12.5126C22.275 13.1488 22.051 13.6937 21.603 14.1473C21.1549 14.6009 20.6065 14.8277 19.9577 14.8277ZM8.7788 17.8277H10.2788V15.8277H12.2788V14.3277H10.2788V12.3277H8.7788V14.3277H6.7788V15.8277H8.7788V17.8277ZM5.32065 22.275C4.86067 22.275 4.46179 22.1055 4.12402 21.7666C3.78626 21.4277 3.61737 21.0274 3.61737 20.5658V11.75C3.61737 10.2985 4.09237 9.02738 5.04237 7.93673C5.99237 6.84606 7.20397 6.16734 8.67717 5.90056V3.43426H6.31357V1.72501H12.6146C13.1374 1.72501 13.6281 1.79964 14.0869 1.94891C14.5456 2.09819 14.9786 2.30979 15.3859 2.58371L14.1543 3.84621C13.917 3.70889 13.6737 3.6059 13.4243 3.53723C13.1749 3.46858 12.9043 3.43426 12.6125 3.43426H10.3864V5.90056C11.8596 6.16734 13.0712 6.84606 14.0212 7.93673C14.9712 9.02738 15.4462 10.2985 15.4462 11.75V20.5658C15.4462 21.0274 15.2763 21.4277 14.9364 21.7666C14.5965 22.1055 14.1987 22.275 13.7429 22.275H5.32065ZM5.32065 20.5658H13.7429V11.75C13.7429 10.5786 13.3334 9.58442 12.5144 8.76741C11.6954 7.95037 10.7043 7.54186 9.5413 7.54186C8.37825 7.54186 7.38405 7.95037 6.5587 8.76741C5.73333 9.58442 5.32065 10.5786 5.32065 11.75V20.5658Z" />
      </svg>
    </SvgIcon>
  );
};

export default MedicationIcon;
