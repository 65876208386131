import { USER_ACTION_TYPES } from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  userData: { first_name: '', last_name: '', account_type: 'free', date_added: '', email: '' },
  signInModal: false,
  createAccountModal: false,
  isLoading: false,
  error: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SIGN_IN_SUCCESS:
      const { currentUser, userData } = payload;
      return {
        ...state,
        currentUser, userData
      };
    case USER_ACTION_TYPES.SIGN_IN_FAILED:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case USER_ACTION_TYPES.SET_SIGN_IN_MODAL:
      return {
        ...state,
        signInModal: payload,
      };
    case USER_ACTION_TYPES.SET_CREATE_ACCOUNT_MODAL:
      return {
        ...state,
        createAccountModal: payload,
      };
    default:
      return state;
  }
};
