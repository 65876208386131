import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import { Modal, Fade, Box, Typography, Button, TextField, Stack, Link } from '@mui/material';

import { createAuthUserWithEmailAndPassword } from '../../utils/firebase/firebase.utils';

import {
  setCurrentUser,
  setCreateAccountModal,
  setSignInModal
} from '../../store/user/user.action';
import { selectCreateAccountModal } from '../../store/user/user.selector';
import { createNewUser } from '../../utils/backend/backend.utils';

const defaultSignInFields = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  verifypassword: ''
};

const CreateAccount = () => {
  const dispatch = useDispatch();

  const createAccountModal = useSelector(selectCreateAccountModal);

  const [formFields, setFormFields] = useState(defaultSignInFields);
  const { firstname, lastname, email, password, verifypassword } = formFields;
  const resetFormFields = () => {
    setFormFields(defaultSignInFields);
  };
  const handleCreateAccountClose = () => dispatch(setCreateAccountModal(false));

  const handleSignInOpen = () => {
    handleCreateAccountClose();
    dispatch(setSignInModal(true));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = await createAuthUserWithEmailAndPassword(email, password);
      dispatch(setCurrentUser(user));
      const userData = {
        user_id: user.user.uid,
        email,
        first_name: firstname,
        last_name: lastname,
        date_added: dayjs(),
        account_type: 'free'
      };
      //dispatch(setUserData(userData));
      dispatch(createNewUser());
      resetFormFields();
      handleCreateAccountClose();
    } catch (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          alert('incorrect password for email');
          break;
        case 'auth/user-not-found':
          alert('no user associated with this email');
          break;
        default:
          console.log(error);
      }
    }
  };
  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={createAccountModal}
        onClose={handleCreateAccountClose}
        closeAfterTransition>
        <Fade in={createAccountModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4
            }}>
            <form onSubmit={handleSubmit}>
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}>
                <Typography id="sign-in-title" variant="h6" component="h2">
                  Create an account
                </Typography>

                <TextField
                  fullWidth
                  id="firstname-input"
                  name="firstname"
                  label="first name"
                  type="text"
                  variant="outlined"
                  onChange={handleChange}
                  value={firstname}
                />
                <TextField
                  fullWidth
                  id="lastname-input"
                  name="lastname"
                  label="last name"
                  type="text"
                  variant="outlined"
                  onChange={handleChange}
                  value={lastname}
                />
                <TextField
                  fullWidth
                  id="email-input"
                  name="email"
                  label="email"
                  type="text"
                  variant="outlined"
                  onChange={handleChange}
                  value={email}
                />
                <TextField
                  fullWidth
                  id="password-input"
                  name="password"
                  label="password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  value={password}
                />
                <TextField
                  fullWidth
                  id="verifypassword-input"
                  name="verifypassword"
                  label="verify password"
                  type="password"
                  variant="outlined"
                  onChange={handleChange}
                  value={verifypassword}
                />

                <Button variant="contained" type="submit">
                  Submit
                </Button>
                <Typography variant="subtitle2">
                  Or{' '}
                  <Link
                    sx={{
                      '&:hover': {
                        cursor: 'pointer'
                      }
                    }}
                    onClick={handleSignInOpen}>
                    Sign in
                  </Link>
                </Typography>
              </Stack>
            </form>
          </Box>
        </Fade>
      </Modal>
    </Fragment>
  );
};

export default CreateAccount;
