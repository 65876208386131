import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HomeIcon = () => {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="none"
      >
        <path d="M5.5538 19.4462H9.14837V13.1484H14.8516V19.4462H18.4462V9.77392L12 4.94075L5.5538 9.7759V19.4462ZM3.85055 21.1495V8.92228L12 2.8087L20.1554 8.92228V21.1495H13.2141V14.7859H10.7859V21.1495H3.85055Z" />
      </svg>
    </SvgIcon>
  );
};

export default HomeIcon;
